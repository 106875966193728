import { Link } from 'react-router-dom';
import Color from 'color';
import styled from 'styled-components';

import isIE from 'utils/isIE';

export const LinkButtonS = styled.button<any>`
  padding: 0.25em 0;
  border: none;
  font-size: 1em;
  color: ${({ disabled, alty, theme, $color }: any) => {
    return disabled ? 'grey' : isIE() ? '' : $color || 'black'; // Color(alty === 'y' ? 'black' : 'black').toString();
  }};
  background: transparent;
  cursor: pointer;
  :hover {
    color: ${({ theme, alty }: any) =>
      isIE()
        ? ''
        : Color(
            alty === 'y' ? theme.primarycolour : theme.primarycolour
          ).string()};
  }
`;

export const StyledLink = styled<any>(Link)`
  font-size: 0.9em;
  font-size: 0.9em;
  color: ${({ disabled, alty, theme }: any) => {
    return disabled
      ? 'grey'
      : isIE()
      ? ''
      : Color(alty === 'y' ? 'black' : 'black').toString();
  }};
  text-decoration: none;
  :hover {
    color: ${({ theme, alty }: any) =>
      isIE()
        ? ''
        : Color(
            alty === 'y' ? theme.primarycolour : theme.primarycolour
          ).string()};
  }
`;
